.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel:not(.vc_active) {
    display: none;
}

.vc_message_box-icon > .fa::before {
    font-size: 25.5px;
}
.accordion__button {
    cursor: pointer;
}

footer.cdb-footer {
    .gform_widget {
        padding-bottom: 0;
    }

    .gform_widget + .widget_text {
        padding-top: 0;
    }
}
.custom-heading-p-tag {
    font-size: 16px !important;
}

.swiper-slide > div > .cdb-grid-section {
    @media screen and (max-width: 640px) {
        background-image: none !important;
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
}